import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Gemini Nature
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/gemini"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Gemini</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Gemini Nature</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Gemini Nature</h1>
                <h2 className="text-sm md:text-base ml-4">May 21 - Jun 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-love");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-nature");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-man");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-traits");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-facts");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              <p className="playfair text-black text-xl md:text-3xl">Gemini Nature</p><br/>
              The twins' emphasis will be mostly on swapping words, knowledge, and ideas. You will instantly react to any provocation in the surroundings and swap ideas and different topics of focus areas. You know how to shift others from their most stubbornly held convictions. You get boosted by gaining understanding out of interest and sharing with people. You probe people and locations in search of information forever. <br></br>
              <br></br>
              Duality is an integral part of your personality. You may have double feelings of love and hate at the same time. You are a multi-tasker. You can effortlessly switch on different thinking or emotions. Consistency is not your virtue. You are so versatile that you will be in one place today and another tomorrow. You change your intellect, spirit, and things very fast. The influence of duality will be on your conclusions and decision. You end up in trouble due to this on numerous occasions. You have strong instincts and know where you stand at that moment. If you are in a situation, you have the right answer. You will use your fast mind and clever tongue to escape dangerous situations. You are impatient with conservative people and those who can't take a stand on a particular issue. You convey your thoughts and ideas very fast and also hear and read at a quick pace. <br></br>
              <br></br>
              You are easygoing, rejoicing, and bright. You may lose good possibilities due to your forgiving, reluctant and fussy nature. Quick-witted wordplays and dynamic conversations inspire you. You are inclined to brainstorm and socialize. You are charming, adorable, playful, wise, and adventurous. You possess admirable memory and suitable grasping power. You are prudent, and your competitors are often complicated by your arguments. Your true motives are always hidden. Sometimes it becomes tough to understand the behavior and the point you want to say. <br></br>
              <br></br>
              <p className="font-bold text-orange-500 inline-block mr-2">Gemini Ruling Planet:</p>Mercury
              The third house represents your thought process, communication skills, and all aspects. It identifies how you process gathered information. It also determines the speed and clarity of thoughts and ideas running in the mind. The house is about how nicely you can display messages to others. It is liable for all the conversations and interactions with friends, coworkerd, associates, and home members. Mercury is the house's ruling planet, and it gives you a powerful connection between the mind and the mouth. The place is also a significator of private and professional journey. <br></br>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Habits</p><br/>
              You require constant inspiration for self-expression. You can't stay on one thing for a long time as you get bored quickly. You will even locomote to a new task before completing the previous ones. You need every minute information for everything. You are always curious to learn new things and often experiment with additional specialties and ideas for that. But occasionally, it may lead you to a problematic situation. You usually bite your nails when you are anxious or self-conscious. You can escalate or fire up any trouble, task, activity, or event. You keep checking your phones obsessively. You get disconnected and distracted very easily. You often feel scattered, so you run up late everywhere. <br></br>
              <br></br>
              <p className="font-bold text-orange-500 inline-block mr-2">Element:</p>Air
              You belong to a mutable air sign; hence, you are constantly lashing up a chattering storm. You are prized with solid mental energy, intelligence, and brilliance. Your life arises from rapid exceptional experiences, understandings, learning, and creative ideas. You are keen to process data, multi-task, and follow keenly. You show great innovative synergy and form an instant harmony with people. You are a friendly butterfly who repeatedly flutters from one exchange to another. You can approach a problem from different directions, selecting the best one from it. You can adapt and fit into various situations hence are referred to as chameleons. However, you tend to get bored fast, which leads to distraction and discontinuation of launched things and tasks. You are a good scholar and never dissatisfy others when they ask for ideas. <br></br>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Strengths</p><br/>
              You are clever, intelligent, enthusiastic, dynamic, adaptable, and quick-witted. You are energetic and nervous about offering your buddies and close companions the fleshiest pieces of information. You will forever be in the presence of exciting people. You possess sound clarity in thinking and will glance at all possible angles before performing any task or project. You still have logical and well-thought, well-thought-out opinions to deal with any situation. You always see a larger picture of your private and professional life. You are talented with uncanny dynamic intelligence who knows what the other person wants to hear from them. You can live a new life in the same day-to-day routine, and the individuals around you are always exhilarated and comfortable in your presence. <br></br>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Weaknesses</p><br/>
              You live in the moment and keep on varying with time. Hence, it becomes challenging for others to comprehend your motives. Your curiousness will force you to understand all areas, but you don't have in-depth knowledge of any size. You often embellish the stories and stretch the facts to make them enjoyable. Your duality will show a transformation of mind and positions now and then. This quality may lead you to some problems in intimate relationships. <br></br>
              <br></br>
              <p className="font-bold text-orange-500 inline-block mr-2">Eminent Personality</p><br/>
              Queen Victoria, Donald Trump, Boris Johnson, Xi Jinping, Venus Williams, Adam Smith, Prince Philip, Evan Spiegel, Angelina Jolie, Chris Pratt, Lauryn Hill, Kendrick Lamar, Colin Farrell, Natalie Portman, Marilyn Monroe, Kayne West, Tom Holland, Iggy Azalea, Ekta Kapoor, Paresh Rawal, Anil Ambani, Sonam Kapoor, Karan Johar, Sonakshi Sinha <br></br>
              <br></br>
      
      </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
